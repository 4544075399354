import { getRareVisits } from '../store';
import { getCurrentPlace } from './getCurrentPlace';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

export function getIsInsightsEmptyForCurrentBuilding(emailAddresses: UserEmailAddress[]): boolean {
    const currentPlace = getCurrentPlace();
    const currentPlaceId = currentPlace?.id;
    const peopleInsights = getRareVisits();

    let insightEmptyState = true;
    const peopleInsightsInBuilding = currentPlaceId ? peopleInsights.get(currentPlaceId) : [];
    peopleInsightsInBuilding?.map(insight => {
        if (emailAddresses.includes(insight)) {
            insightEmptyState = false;
        }
    });
    return insightEmptyState;
}
