import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { isFeatureEnabled } from 'owa-feature-flags';
import { loadUpsCollaboratorsBuildingLocations } from 'places-ups-location-operations';
import { orchestrator } from 'satcheljs';

orchestrator(collaboratorsFetchedAction, ({ collaborators }) => {
    // make the ups call to get the presence information for the collaborators
    if (isFeatureEnabled('msplaces-enable-ups-checkin')) {
        loadUpsCollaboratorsBuildingLocations(collaborators, 'building');
    }
});
