import { getSelectedCalendarsForUser } from 'owa-calendar-module-selected-calendars-user-config/lib/selectors/getSelectedCalendarsForUser';
import { getSortedCalendarEntriesFromIds } from './getSortedCalendarEntriesFromIds';
import type { CalendarEntry } from 'owa-graph-schema';
import { getMailboxInfoForUserIdentity } from 'owa-account-source-list';

export function getSelectedCalendarEntriesForUser(userIdentity: string): CalendarEntry[] {
    const mailboxInfo = getMailboxInfoForUserIdentity(userIdentity);
    const selectedCalendars = getSelectedCalendarsForUser(mailboxInfo);
    const calEntries = getSortedCalendarEntriesFromIds(selectedCalendars, userIdentity);

    return calEntries ? calEntries : [];
}
