import type { ObservableMap } from 'mobx';
import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

import type EventifyListViewEvent from 'eventify-schema/lib/EventifyListViewEvent';

const getStore = createStore('places-events-store', {
    eventsInLocations: null as ObservableMap<string, null | EventifyListViewEvent[]> | null, // Stores events per building id
});

export const store = getStore();

const { getEventsInLocations, setEventsInLocations } = createAccessors(
    'places-events-store',
    getStore
);

export { getEventsInLocations, setEventsInLocations };
