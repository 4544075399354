import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { addDays } from 'owa-datetime';
import { createDateRange } from 'owa-datetime-utils';
import { isFeatureEnabled } from 'owa-feature-flags';
import { loadAllPeopleInsights } from 'places-people-insights-operations';
import { orchestrator } from 'satcheljs';

import { getCurrentDate } from '../store';

orchestrator(collaboratorsFetchedAction, ({ collaborators }) => {
    if (isFeatureEnabled('msplaces-explore-people-rare-visits')) {
        const currentDate = getCurrentDate();
        const pastDateStart = addDays(currentDate, -14);
        loadAllPeopleInsights(createDateRange(pastDateStart, 15), collaborators);
    }
});
