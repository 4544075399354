import type { CalendarSurfaceOptions } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-options';

export default function getIsDynamicColumnWidthEnabled(): boolean {
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );

    return userOptions.isDynamicColumnWidthEnabled;
}
