import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { CalendarViewDocument } from 'hybridspace-common/lib/graphql/__generated__/CalendarViewQuery.interface';
import { useManagedQueryWithError } from 'hybridspace-graphql/lib/utils/useManagedQueryWithError';
import { getISOString } from 'owa-datetime';

import type { OwaDate } from 'owa-datetime';

export default function useCalendarView(
    startDate: OwaDate,
    endDate: OwaDate,
    skip = false,
    maxResults = 25
) {
    const smtpAddress = getUserEmailAddress();
    return useManagedQueryWithError(CalendarViewDocument, {
        variables: {
            input: {
                dateRange: {
                    start: getISOString(startDate),
                    end: getISOString(endDate),
                },
                calendarEntryData: {
                    calendarId: {
                        id: 'HybridspaceCalendarView', // this var is not required on the server side, but it needs to be non-nullable
                        mailboxInfo: {
                            mailboxSmtpAddress: smtpAddress,
                            userIdentity: smtpAddress,
                        },
                    },
                },
                maxResults,
            },
        },
        skip: skip || !smtpAddress,
        fetchPolicy: 'network-only',
    });
}
