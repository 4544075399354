import type { CalendarEvent, CalendarParticipant, Location } from 'owa-graph-schema';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { getPlace } from 'owa-location-service';
import { setIsAttendeeARoom } from '../mutators';
import { isAttendeeARoom, isAttendeeCached } from '../store';

export default function hasRoom(event: CalendarEvent): boolean {
    const locations = event?.locations || ([] as Location[]);

    if (locations?.some(location => location?.locationType === 'ConferenceRoom')) {
        // If the locations have a conference room, then we consider the event has a conf room
        return true;
    }

    // If the locations don't have a conference room, we need to check the resources attendees since the server side code to stamp
    // the locations collection with conf rooms is not WW yet
    const resourcesAttendees = (event?.meetingDetails?.resources || []) as CalendarParticipant[];
    if (resourcesAttendees?.some(isAttendeeRoom)) {
        // If any of the optional attendees is a conference room, then we consider the event has a conf room
        return true;
    }

    // If there is no room in locations or optional attendees, then we consider the event does not have a conf room
    return false;
}

function isAttendeeRoom(attendee: CalendarParticipant): boolean {
    const email = attendee?.userIdentity?.emailAddress;
    if (!email) {
        return false;
    }

    if (!isAttendeeCached(email)) {
        // If we have not previously cached this attendee, call the service and cache the result. But return true for now
        setIsAttendeeARoom(email, true);
        getPlace(email)
            .then(response =>
                !!response && isSuccessStatusCode(response.status)
                    ? response.json()
                    : Promise.resolve(false)
            )
            .then(result => setIsAttendeeARoom(email, !!result && result.Type == 'Room'));
    }

    return isAttendeeARoom(email);
}
