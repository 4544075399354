import { getNonNullCalendarsFromCalendarIds } from 'owa-calendar-cache';
import { sortCalendarEntries } from './sortCalendarEntries';
import type { CalendarEntry } from 'owa-graph-schema';

export function getSortedCalendarEntriesFromIds(
    calendarIds: string[],
    userIdentity: string
): CalendarEntry[] {
    const calEntries = getNonNullCalendarsFromCalendarIds(calendarIds);
    const sortedCalendars = sortCalendarEntries(calEntries, userIdentity);

    return sortedCalendars;
}
