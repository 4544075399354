import { ObservableMap } from 'mobx';
import { loadEventsInLocationsSucceeded, noBuilding } from 'places-events-store-operations';
import { orchestrator } from 'satcheljs';
import { getEventsInLocations, setEventsInLocations } from '../store';

import type EventifyListViewEvent from 'eventify-schema/lib/EventifyListViewEvent';
orchestrator(loadEventsInLocationsSucceeded, actionMessage => {
    // get the map of events per building
    const currentEventsInLocations =
        getEventsInLocations() ?? new ObservableMap<string, null | EventifyListViewEvent[]>();
    if (actionMessage.buildingId === noBuilding) {
        // call the rest of the events
        const totalEventsForDay = actionMessage.result;
        // for each event, check if the location is different from the current building
        totalEventsForDay.forEach(event => {
            const eventLocations = event.locations;
            eventLocations.forEach(location => {
                // if the location is different from the current building, add the event to the set
                if (location?.BuildingId) {
                    const newSetEventsForLocation =
                        currentEventsInLocations.get(location.BuildingId) || [];
                    // check if the event is already in the set
                    if (!newSetEventsForLocation.some(newEvent => newEvent.id === event.id)) {
                        // add the event to the set
                        newSetEventsForLocation.push(event);
                        currentEventsInLocations.set(location.BuildingId, newSetEventsForLocation);
                    }
                }
            });
        });
    } else {
        // update the map with the new events
        currentEventsInLocations.set(actionMessage.buildingId, actionMessage.result);
    }
    setEventsInLocations(currentEventsInLocations);
});
