import { lazyGetTenantsEventifyListViewEvents } from 'eventify-client';
import { getEntryPoint } from 'hybridspace-telemetry';
import { DatapointStatus } from 'owa-analytics-types';
import { PerformanceDatapoint } from 'owa-analytics/lib/datapoints/PerformanceDatapoint';
import { now, utcDate } from 'owa-datetime';
import { noBuilding } from '../constants/constants';
import { getDevErrorPageState } from 'places-diagnostics';

import type { EventLocationFilters } from 'eventify-schema/lib/EventLocationFilters';
import type EventifyListViewEvent from 'eventify-schema/lib/EventifyListViewEvent';

const DEFAULT_TOP_EVENTS_THRESHOLD = 8; // number of events to show

export default async function getEventifyEventsForToday(
    buildingId: string = noBuilding,
    customThreshold?: number
): Promise<EventifyListViewEvent[]> {
    const endTimeLowerBoundDate = now();
    const endTimeLower = utcDate(endTimeLowerBoundDate);

    let upcomingEvents: EventifyListViewEvent[] = [];

    const eventLocationFilters: EventLocationFilters = {
        buildingId,
    };
    // provide the results from today until the end of next week
    const upcomingEventsDataPoint = new PerformanceDatapoint('PlacesUpcomingEvents');
    upcomingEventsDataPoint.addCustomData({
        entryPoint: getEntryPoint(),
    });
    try {
        if (getDevErrorPageState() === 'Events') {
            throw new Error('could not get events');
        }
        const eventsResponse = await lazyGetTenantsEventifyListViewEvents.importAndExecute(
            undefined /* startTimeLower */,
            undefined /* startTimeUpper */,
            endTimeLower /* endTimeLower */,
            undefined /* endTimeUpper */,
            false /* descendingSort */,
            null /* hasEmbeddedRecording */,
            undefined /* eventFilters */,
            false /* shouldUseDefaultEventFilters */,
            buildingId !== noBuilding ? eventLocationFilters : undefined /* eventLocationFilters */,
            customThreshold || DEFAULT_TOP_EVENTS_THRESHOLD /* top */,
            true /* shouldThrowServiceError */
        );

        if (eventsResponse?.events) {
            upcomingEvents = eventsResponse.events;
            upcomingEventsDataPoint.addCustomData({
                eventsCount: upcomingEvents.length,
            });
            upcomingEventsDataPoint.end();
        } else {
            const error = new Error('PlacesGetEventsForToday throw error: no events found');
            upcomingEventsDataPoint.endWithError(DatapointStatus.ServerError, error);
            throw error;
        }
    } catch (error) {
        upcomingEventsDataPoint.endWithError(DatapointStatus.ServerError, error);
        throw new Error('Eventify events API throw error');
    }
    return upcomingEvents;
}
