import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { DateRange } from 'owa-datetime-utils';
import getScheduleWithLocation from 'hybridspace-common/lib/services/getScheduleWithLocation';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { buildChunks } from 'hybridspace-common/lib/utils';

import type {
    HybridspaceGetScheduleFlexibleWorkingHoursView,
    HybridspaceScheduleInformation,
} from 'owa-graph-schema';

export default async function fetchAllPeopleInsights(
    dateRange: DateRange,
    collaborators: UserEmailAddress[]
): Promise<Map<string, UserEmailAddress[]>> {
    const perfDatapoint = new PerformanceDatapoint('PlacesFetchPeopleInsights');

    perfDatapoint.addCustomData({
        dateRange: getDateRangeISOString(dateRange),
    });

    // Initialize the map that will store <buildingId, array of collaborators>
    const buildingToCollaboratorsMap = new Map<string, UserEmailAddress[]>();

    try {
        // Break collaborators into chunks
        const batchSize = 20;

        const collaboratorChunks = buildChunks(collaborators, batchSize) as UserEmailAddress[][];

        // Fetch schedules in batches of collaborators at a time
        const schedulesChunks = await Promise.allSettled(
            collaboratorChunks.map(chunk => getScheduleWithLocation(chunk, dateRange))
        );

        // Flatten the array of schedules into one list
        const schedules = schedulesChunks
            .filter(
                (result): result is PromiseFulfilledResult<HybridspaceScheduleInformation[]> =>
                    result.status === 'fulfilled'
            )
            .flatMap(result => result.value);

        collaborators.map(collaborator => {
            // Filter out any undefined or null schedule options before finding the correct schedule
            const validSchedules = schedules?.filter(
                (scheduleOption): scheduleOption is HybridspaceScheduleInformation =>
                    scheduleOption !== undefined && scheduleOption !== null
            );

            const schedule = validSchedules?.find(
                (scheduleOption: HybridspaceScheduleInformation) =>
                    scheduleOption.scheduleId === collaborator
            );

            // Collect building IDs from the collaborator's flexible working hours
            const buildingIds = schedule?.flexibleWorkingHours
                ?.map(
                    (flexibleWorkingHour: HybridspaceGetScheduleFlexibleWorkingHoursView) =>
                        flexibleWorkingHour.building?.id
                )
                .filter(id => id !== undefined); // Filter out undefined building IDs

            if (buildingIds) {
                const buildingIdCounts = new Map<string, number>();
                buildingIds.forEach(buildingId => {
                    if (buildingId) {
                        buildingIdCounts.set(
                            buildingId,
                            (buildingIdCounts.get(buildingId) || 0) + 1
                        );
                    }
                });
                buildingIdCounts.forEach((count, buildingId) => {
                    if (count === 1) {
                        // This buildingId appears in exactly one collaborator's schedule
                        if (!buildingToCollaboratorsMap.has(buildingId)) {
                            buildingToCollaboratorsMap.set(buildingId, [collaborator]);
                        } else {
                            buildingToCollaboratorsMap.get(buildingId)?.push(collaborator);
                        }
                    }
                });
            }
        });
    } catch (error) {
        // Handle any errors that occur during the scheduling fetch process
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
        throw error;
    }

    perfDatapoint.end();
    return buildingToCollaboratorsMap;
}
