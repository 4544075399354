import { createObservableOperation } from 'owa-observable-operation';
import { noBuilding } from '../constants/constants';
import getEventifyEventsForToday from '../services/getEventifyEventsForToday';

const {
    getLastError: getEventsInLocationsLastError,
    start: loadEventsInLocations,
    isInProgress: loadEventsInLocationsInProgress,
    onStarted: loadEventsInLocationsStarted,
    onSucceeded: loadEventsInLocationsSucceeded,
    onFailed: loadEventsInLocationsFailed,
    onEnded: loadEventsInLocationsEnded,
} = createObservableOperation(
    'PLACES_LOAD_EVENTS_FOR_LOCATIONS',
    (buildingId: string = noBuilding) => ({
        // using all when there is no buildingId to get all events
        buildingId,
        key: buildingId,
    }),
    ({ key }) => key,
    ({ buildingId }) => getEventifyEventsForToday(buildingId)
);

export {
    getEventsInLocationsLastError,
    loadEventsInLocations,
    loadEventsInLocationsEnded,
    loadEventsInLocationsFailed,
    loadEventsInLocationsStarted,
    loadEventsInLocationsInProgress,
    loadEventsInLocationsSucceeded,
};
