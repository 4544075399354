import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { createDateRange } from 'owa-datetime-utils';
import { getCollaboratorsLocationsLastError } from 'places-explore-operations';
import { getServiceCallsCompleted, useCollaboratorLocationFromCache } from 'places-people-store';
import React from 'react';
import { getCollaboratorsFromLocation } from '../selectors/getCollaboratorsFromLocation';

import type { OwaDate } from 'owa-datetime';
/**
 * @param date: the date for which we want to grab the collaborators
 * @param buildingId: the buildingId for which we want to grab the collaborators
 * returns data, loading and error. If we have cached data we return that in the case of
 * error or loading. If we don't have cached data, we return the network data and its
 * corresponding states.
 */
export function useCollaborators(date: OwaDate, buildingId: string) {
    const dateRange = createDateRange(date, 2);
    const key = getDateRangeISOString(dateRange);

    const cachedData = useCollaboratorLocationFromCache(date, buildingId);

    const isCachedDataUnavailable = cachedData === undefined || cachedData.length === 0;

    // getting network data and status of network calls
    const isOperationLoading = !getServiceCallsCompleted().includes(key);
    const operationError = getCollaboratorsLocationsLastError(key);
    const operationData = getCollaboratorsFromLocation(buildingId);

    /** data and loading states considering cached data, network data and their respective states */
    // loading if both cached data and network data are unavailable
    const loading = isCachedDataUnavailable && isOperationLoading && operationData === undefined;
    // we are prioritizing the cached data over error
    const error = isCachedDataUnavailable && !isOperationLoading ? operationError : undefined;
    // show cached data immediately while network call is loading
    const basedData = operationData ?? cachedData ?? [];
    const data = React.useMemo(
        () => basedData.map(collaborator => collaborator.toLowerCase()),
        [basedData]
    );
    return { data, loading, error };
}
