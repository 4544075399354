import { orchestrator } from 'satcheljs';

import { loadAllPeopleInsightsSucceeded } from 'places-people-insights-operations';
import { addRareVisit } from '../store';

orchestrator(loadAllPeopleInsightsSucceeded, ({ result }) => {
    // Loop through the Map of <buildingId, array of collaborators>
    result.forEach((collaborators, buildingId) => {
        // Call addRareVisit for each buildingId with its corresponding collaborators
        addRareVisit(buildingId, collaborators);
    });
});
