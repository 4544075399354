import {
    getEventsInLocationsLastError,
    loadEventsInLocationsInProgress,
    noBuilding,
} from 'places-events-store-operations';
import { getEventsInLocations } from '../store';

export function getEventsInLocationsLoading(buildingId: string = noBuilding) {
    return loadEventsInLocationsInProgress(buildingId);
}

export function getEventsInLocationsError(buildingId: string = noBuilding) {
    return getEventsInLocationsLastError(buildingId);
}

export function getEventsFromBuildingId(buildingId: string = noBuilding) {
    const eventsInLocation = getEventsInLocations();
    if (!eventsInLocation) {
        return undefined;
    }
    return eventsInLocation.get(buildingId);
}
