import initializeCalendarEvent from 'owa-calendar-forms-common/lib/utils/initializeCalendarEvent';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

import type { CalendarEvent } from 'owa-calendar-types';

// Note: Calendar cache needs to be initialized before calling this function, otherwise ParentFolderId will be set to null.
// This is because initializeCalendarEvent calls getCalendarFolderId which uses the calendar cache.
export default function initializeFilledCalendarEvent(data: Partial<CalendarEvent> = {}) {
    return initializeCalendarEvent(
        {
            RequiredAttendees: [],
            OptionalAttendees: [],
            FreeBusyType: 'Free',
            Locations: [],
            ...data,
        },
        undefined,
        true, //Should respect start/end datetimes (ignore speedy meetings)
        undefined,
        getModuleContextMailboxInfo()
    );
}
