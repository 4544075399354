import { getEventsInLocations } from 'places-events-store';
import { mutatorAction } from 'satcheljs';
import { store } from '../store';

export const setCheckCurrentBuildingEvents = mutatorAction(
    'setCheckCurrentBuildingEvents',
    (buildingId: string) => {
        const allEvents = getEventsInLocations();
        if (!allEvents) {
            store.currentEvents = null;
        }
        const newCurrentEvents = allEvents?.get(buildingId) || undefined;
        store.currentEvents = newCurrentEvents === undefined ? [] : newCurrentEvents;
    }
);
