import { createLazyComponent, LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "EventifyClient" */ './lazyIndex'),
    {
        initializer: m => m.initializeBundle,
    }
);

// Selectors
// -- For Route handling
export const lazyGetCurrentRoute = new LazyImport(lazyModule, m => m.getCurrentRoute);
export const lazyGetCurrentModule = new LazyImport(lazyModule, m => m.getCurrentModule);
export const lazyGetCurrentPageTitle = new LazyImport(lazyModule, m => m.getCurrentPageTitle);

// Lazy Commponents
// -- For Main module
export const EventifyHeader = createLazyComponent(lazyModule, m => m.EventifyHeader);
export const EventifyNavBar = createLazyComponent(lazyModule, m => m.EventifyNavBar);
export const EventifyHeaderNavBar = createLazyComponent(lazyModule, m => m.EventifyHeaderNavBar);
export const EventifyDashboard = createLazyComponent(lazyModule, m => m.EventifyDashboard);
export const ComposeForm = createLazyComponent(lazyModule, m => m.ComposeForm);
export const ReadModule = createLazyComponent(lazyModule, m => m.ReadModule);
export const DevPanel = createLazyComponent(lazyModule, m => m.DevPanel);
export const EventifySearchResult = createLazyComponent(lazyModule, m => m.EventifySearchResult);
export const EventErrorPage = createLazyComponent(lazyModule, m => m.EventErrorPage);
export const UnauthorizedAccessErrorPage = createLazyComponent(
    lazyModule,
    m => m.UnauthorizedAccessErrorPage
);
export const SearchErrorPage = createLazyComponent(lazyModule, m => m.SearchErrorPage);
export const TenantAdminPortal = createLazyComponent(lazyModule, m => m.TenantAdminPortal);
export const ModeratorPortal = createLazyComponent(lazyModule, m => m.ModeratorPortal);
export const PromotionalEmailPage = createLazyComponent(lazyModule, m => m.PromotionalEmailPage);
export const EventsGroupComposeForm = createLazyComponent(
    lazyModule,
    m => m.EventsGroupComposeForm
);
export const EventsGroupReadModule = createLazyComponent(lazyModule, m => m.EventsGroupReadModule);
export const EventsGroupErrorPage = createLazyComponent(lazyModule, m => m.EventsGroupErrorPage);
export const FaqPage = createLazyComponent(lazyModule, m => m.FaqPage);
export const NoEventifyAccess = createLazyComponent(lazyModule, m => m.NoEventifyAccess);

// Lazy Actions
// -- For Route handling and Main module
export const lazySetCurrentModule = new LazyAction(lazyModule, m => m.setCurrentModule);
export const lazyShowDashboard = new LazyAction(lazyModule, m => m.showDashboard);
export const lazyViewEvent = new LazyAction(lazyModule, m => m.viewEvent);
export const lazyNewEvent = new LazyAction(lazyModule, m => m.newEvent);
export const lazyEditEvent = new LazyAction(lazyModule, m => m.editEvent);
export const lazyNewEventsGroup = new LazyAction(lazyModule, m => m.newEventsGroup);
export const lazyEditEventsGroup = new LazyAction(lazyModule, m => m.editEventsGroup);
export const lazyViewEventsGroup = new LazyAction(lazyModule, m => m.viewEventsGroup);
export const lazyOpenDevPanel = new LazyAction(lazyModule, m => m.openDevPanel);
export const lazySearch = new LazyAction(lazyModule, m => m.searchEvents);
export const lazyOpenTenantAdminPortal = new LazyAction(lazyModule, m => m.openTenantAdminPortal);
export const lazyOpenModeratorPortal = new LazyAction(lazyModule, m => m.openModeratorPortal);
export const lazyReorderConnectedEventGroupAction = new LazyAction(
    lazyModule,
    m => m.reorderConnectedEventGroupAction
);
export const lazyInitializeTenantConfig = new LazyAction(lazyModule, m => m.initializeTenantConfig);

export const lazyLoadBootstrapData = new LazyAction(lazyModule, m => m.loadBootstrapData);
export const lazyInitializePostLazyAction = new LazyAction(
    lazyModule,
    m => m.initializePostLazyAction
);

// -- For services

export const lazyGetTenantsEventifyListViewEvents = new LazyAction(
    lazyModule,
    m => m.getTenantsEventifyListViewEvents
);
export const lazyGetEventTheme = new LazyAction(lazyModule, m => m.getEventTheme);
export const lazyRespondToEvent = new LazyAction(lazyModule, m => m.respondToEvent);
export { lazyShowResponseNotification } from './utils';

export { LogIfValidExternalEntryPoint } from './ExternalEntryPoint';

//Types
export type { EventifyModule } from './store/schema/EventifyStore';
