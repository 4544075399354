import type { CalendarSurfaceOptions } from 'owa-outlook-service-options';
import {
    lazyCreateOrUpdateOptionsForFeature,
    OwsOptionsFeatureType,
    getOptionsForFeature,
    lazyLoadOptions,
} from 'owa-outlook-service-options';

export function updateNumDaysInDayRangeStoredValue(newValue: number) {
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );
    if (userOptions.numDaysInDayRange != newValue) {
        userOptions.numDaysInDayRange = newValue;
    }
}
export async function updateNumDaysInDayRangeServerValue(newValue: number) {
    await lazyLoadOptions.importAndExecute();
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );
    lazyCreateOrUpdateOptionsForFeature.importAndExecute(
        OwsOptionsFeatureType.CalendarSurfaceOptions,
        {
            ...userOptions,
            numDaysInDayRange: newValue,
        } as CalendarSurfaceOptions
    );
}
