import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-options';
import type { TimeScaleSetting } from 'owa-calendar-timescale';
import { DEFAULT_TIME_SCALE } from 'owa-calendar-timescale';

const getTimeScaleSettingComputed: IComputedValue<TimeScaleSetting> = computed(() => {
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );
    const timeScaleSetting =
        userOptions.timeScaleSetting == 0 ? DEFAULT_TIME_SCALE : userOptions.timeScaleSetting;
    return timeScaleSetting as TimeScaleSetting;
});

export default function getTimeScaleSetting(): TimeScaleSetting {
    return getTimeScaleSettingComputed.get();
}
