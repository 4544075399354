import { mutatorAction } from 'satcheljs';
import { getEventsInLocations, store } from '../store';

import type RegistrationType from 'eventify-schema/lib/RegistrationType';

export const eventRegistrationMutator = mutatorAction(
    'eventRegistrationMutator',
    (buildingId: string, eventId: string, registrationStatus: RegistrationType) => {
        const currentEventsInLocations = getEventsInLocations();
        if (currentEventsInLocations) {
            const eventToUpdateRegistration = currentEventsInLocations.get(buildingId);
            if (eventToUpdateRegistration) {
                const eventIndex = eventToUpdateRegistration.findIndex(
                    event => event.id === eventId
                );
                if (eventIndex !== -1) {
                    eventToUpdateRegistration[eventIndex].registrationType = registrationStatus;
                    currentEventsInLocations.set(buildingId, eventToUpdateRegistration);
                    store.eventsInLocations = currentEventsInLocations;
                }
            }
        }
    }
);
