import { logUsage } from 'owa-analytics';

export const ExternalEntryPointList = [
    'owa', // eventify entrypoint in owa.
    'owaCalendarCreateLargeEvent', // eventify entrypoint in owaCalendar => Create Event > Large Event.
];
export type ExternalEntryPointType = typeof ExternalEntryPointList[number];

export function LogIfValidExternalEntryPoint(inputEntryPoint: string): void {
    // if the entryPoint provided is a valid external entrypoint, only then we will log the datapoint.
    if (ExternalEntryPointList.includes(inputEntryPoint)) {
        switch (inputEntryPoint) {
            case 'owa':
                logUsage('EVENTIFY_EXTERNAL_ENTRYPOINT_OWA_LEFT_PANEL');
                break;
            case 'owaCalendarCreateLargeEvent':
                logUsage('EVENTIFY_EXTERNAL_ENTRYPOINT_OWA_CALENDAR_CREATE_LARGEEVENT');
                break;
        }
    }
}
