import { loadEventsInLocationsSucceeded } from 'places-events-store-operations';
import { orchestrator } from 'satcheljs';
import { getCurrentPlace } from '../selectors/getCurrentPlace';
import { setCurrentEvents } from '../store';

orchestrator(loadEventsInLocationsSucceeded, actionMessage => {
    const currentPlace = getCurrentPlace();
    if (currentPlace && actionMessage.buildingId === currentPlace.id) {
        setCurrentEvents(actionMessage.result);
    }
});
