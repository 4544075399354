import { getRareVisits } from '../store';
import { getCurrentPlace } from './getCurrentPlace';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

export function isRareVisit(emailAddress: UserEmailAddress): boolean {
    const peopleInsights = getRareVisits();
    const currentPlace = getCurrentPlace();
    const currentPlaceId = currentPlace?.id;
    const peopleInsightsInBuilding = currentPlaceId ? peopleInsights.get(currentPlaceId) : [];

    return peopleInsightsInBuilding?.includes(emailAddress as UserEmailAddress) ?? false;
}
